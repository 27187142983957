import { CalendarDateProps } from "../../../Components/DataEntry/CalendarPicker/types";
import { priceProps } from "./types";

const padToTwoDigits = (num: number) => {
  return num.toString().padStart(2, "0");
};

function parseDateString(dateString: string) {
  const dateParts = dateString.split("/");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Mês no objeto Date é 0-indexado
  const year = parseInt(dateParts[2], 10);

  const date = new Date(year, month, day);

  const weekDay = date.getDay();

  return { day, month: month + 1, year, weekDay };
}

function minuteToHour(minutos: number, addZeroMinute?: boolean): string {
  const horas: number = Math.floor(minutos / 60);
  const minutosRestantes: number = minutos % 60;

  const horasFormatadas: string = horas.toString();
  const minutosFormatados: string = minutosRestantes.toString();

  //   const horasFormatadas: string = horas.toString().padStart(2, '0');
  //   const minutosFormatados: string = minutosRestantes
  //     .toString()
  //     .padStart(2, '0');

  return `${horasFormatadas}h ${
    addZeroMinute && minutosRestantes === 0
      ? `0${minutosFormatados}`
      : minutosFormatados
  }min`;
}

function minuteToClock(minutos: number): string {
  const horas: number = Math.floor(minutos / 60);
  const minutosRestantes: number = minutos % 60;

  // const horasFormatadas: string = horas.toString();
  // const minutosFormatados: string = minutosRestantes.toString();

  const horasFormatadas: string = horas.toString().padStart(2, "0");
  const minutosFormatados: string = minutosRestantes
    .toString()
    .padStart(2, "0");

  return `${horasFormatadas}:${minutosFormatados}`;
}

function parseAmout(valor: number, currency?: string): priceProps {
  const numeroFormatado = (valor / 1000).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let [beforeCents, cents] = numeroFormatado.split(",");

  // Se `cents` não for definido, define-o como '00'
  if (!cents) {
    cents = "00";
  } else if (cents.length < 2) {
    // Se `cents` tiver apenas 1 dígito, adiciona um '0' ao final
    cents = cents.padEnd(2, "0");
  }

  const price: priceProps = {
    fullPrice: numeroFormatado,
    beforeCents,
    cents,
  };

  return price;
}

function differenceDaysBetweenDates(
  departureDate: string,
  arrivalDate: string
): number {
  function parseDate(dateString: string): Date {
    const [day, month, year] = dateString
      .split("/")
      .map((part) => parseInt(part, 10));
    return new Date(year, month - 1, day);
  }

  const departure = parseDate(departureDate);
  const arrival = parseDate(arrivalDate);

  const differenceInTime = arrival.getTime() - departure.getTime();

  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
}

function differenceInMinutes(time1: string, time2: string): string {
  if (time1 && time2) {
    const [hour1, minute1] = time1.split(":").map(Number);
    const [hour2, minute2] = time2.split(":").map(Number);

    const minutes1 = hour1 * 60 + minute1;
    const minutes2 = hour2 * 60 + minute2;

    return minuteToHour(Math.abs(minutes1 - minutes2));
  } else {
    return "";
  }
}

function formatDatesWithLeadingZeros(dates: string[]): string[] {
  const formattedDates: string[] = [];

  for (const dateStr of dates) {
    const parts = dateStr.split("-");
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1].length === 1 ? `0${parts[1]}` : parts[1];
      const day = parts[2].length === 1 ? `0${parts[2]}` : parts[2];
      formattedDates.push(`${year}-${month}-${day}`);
    } else {
      formattedDates.push(dateStr);
    }
  }

  return formattedDates;
}

function sortDatesArray(
  arrayDeObjetos: CalendarDateProps[]
): CalendarDateProps[] {
  return arrayDeObjetos.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    }
    if (a.month !== b.month) {
      return a.month - b.month;
    }
    return a.day - b.day;
  });
}

function convertArrayStringToObjects(
  dateStrings: string[]
): CalendarDateProps[] {
  return dateStrings.map((dateString) => {
    const [yearStr, monthStr, dayStr] = dateString.split("-");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr);
    const day = parseInt(dayStr);

    return { year, month, day };
  });
}

function convertStrigToMinutes(dataHoraISO: string): number {
  const dataHora = new Date(dataHoraISO);

  const dataHoraAtualBrasil = new Date();

  dataHoraAtualBrasil.setHours(dataHoraAtualBrasil.getHours());

  const diferencaEmMinutos = Math.floor(
    (dataHoraAtualBrasil.getTime() - dataHora.getTime()) / 60000
  );

  return diferencaEmMinutos;
}

function getCurrentTime() {
  const currentDate = new Date();
  const brasiliaTimeZone = "America/Sao_Paulo";

  currentDate.toLocaleString("en-US", { timeZone: brasiliaTimeZone });

  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const brasiliaTime = `${hours}:${minutes}:${seconds}`;

  return brasiliaTime;
}

function transformDatesToGTM(
  departureDatesList: string[],
  returnDatesList: string[]
): any {
  const departureDates: any = {};
  const returnDates: any = {};

  departureDatesList.forEach((departureDate, index) => {
    const [yyyy, mm, dd] = departureDate.split("-");
    departureDates[`departure_yyyy_0${index + 1}`] = yyyy;
    departureDates[`departure_mm_0${index + 1}`] = mm;
    departureDates[`departure_dd_0${index + 1}`] = dd;
  });

  returnDatesList.forEach((returnDate, index) => {
    const [yyyy, mm, dd] = returnDate.split("-");
    returnDates[`return_yyyy_0${index + 1}`] = yyyy;
    returnDates[`return_mm_0${index + 1}`] = mm;
    returnDates[`return_dd_0${index + 1}`] = dd;
  });

  return { ...departureDates, ...returnDates };
}

function calculateDateDifference(
  departureDatesList: string[],
  returnDatesList: string[]
): number | null {
  if (returnDatesList.length === 0) {
    return null;
  }

  const departureDates = departureDatesList.map((date) =>
    new Date(date).getTime()
  );
  const returnDates = returnDatesList.map((date) => new Date(date).getTime());

  const minDepartureDate = Math.min(...departureDates);
  const maxReturnDate = Math.max(...returnDates);

  const differenceInMilliseconds = maxReturnDate - minDepartureDate;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return differenceInDays + 1;
}

export {
  parseDateString,
  minuteToHour,
  parseAmout,
  differenceInMinutes,
  minuteToClock,
  formatDatesWithLeadingZeros,
  sortDatesArray,
  convertArrayStringToObjects,
  convertStrigToMinutes,
  getCurrentTime,
  transformDatesToGTM,
  calculateDateDifference,
  padToTwoDigits,
  differenceDaysBetweenDates,
};
