import { useSelector } from "react-redux";
import OfferButton from "../Components/OfferButton";
import { OwnProps } from "./types";
import { getSearchIntent } from "../../../store/flights";
import { getEnvironment } from "../../../core/helpers/Environments";
import { getCountryCodeByAirport } from "./services/airportService";
import { triggerInlineClickEvent } from "./services/analyticsService";

import "./InlineBanner.css";

function InlineBanner({ inlineData }: OwnProps) {
  const environmentType = getEnvironment();
  const searchIntent = useSelector(getSearchIntent);
  const countryOrigin = getCountryCodeByAirport(searchIntent?.originAirport);
  const countryDestination = getCountryCodeByAirport(
    searchIntent?.destinationAirport
  );

  function handleTriggerEvent() {
    triggerInlineClickEvent({
      inlineClient: inlineData!.id ?? inlineData!.title,
      environmentType,
      searchIntent,
      countryOrigin,
      countryDestination,
    });

    window.open(inlineData?.url, "_blank");
  }

  return (
    <>
      <div className="div-inlineBanner">
        <div className="div-inlineBanner-image-content">
          <div className="div-inlineBanner-image">
            <img
              className="img-inlineBanner"
              src={`https:${inlineData?.image}`}
              alt="Encontre passagens aéreas baratas com o Voopter"
            />
          </div>
          <div className="div-inlineBanner-content">
            <div className="title-inlineBanner">
              <span className="title-text-inlineBanner">{`${inlineData?.title}`}</span>
            </div>
            <div className="desciption-inlineBanner">
              <span className="desciption-text-inlineBanner">{`${inlineData?.description}`}</span>
            </div>
          </div>
        </div>

        <div className="div-inlineBanner-button">
          <OfferButton labael="VER OFERTA" onClick={handleTriggerEvent} />
        </div>
      </div>
    </>
  );
}

export default InlineBanner;
