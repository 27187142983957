import airportsData from "../../../Components/Search/SearchFlights/InputSearch/airports.json";
import { AirportsFilterProps } from "../../../store/flights/types";

function sortByPrice(a: any, b: any) {
  const priceA = parseInt(a.pricingOptions[0].price);
  const priceB = parseInt(b.pricingOptions[0].price);

  if (priceA < priceB) {
    return -1;
  } else if (priceA > priceB) {
    return 1;
  } else {
    return 0;
  }
}

function pricingOptionsSortByPrice(a: any, b: any) {
  return parseInt(a.price) - parseInt(b.price);
}

function groupFlights(flighs: any): any[] {
  let groupedFlights = JSON.parse(JSON.stringify(flighs));

  for (const [indexTarget, flighItemTarget] of flighs.entries()) {
    for (const [index, flighItem] of flighs.entries()) {
      const outboundMatch =
        flighItemTarget.outboundFlights.flight.departureDate ===
          flighItem.outboundFlights.flight.departureDate &&
        flighItemTarget.outboundFlights.flight.arrivalDate ===
          flighItem.outboundFlights.flight.arrivalDate &&
        flighItemTarget.outboundFlights.flight.departureTime ===
          flighItem.outboundFlights.flight.departureTime &&
        flighItemTarget.outboundFlights.flight.arrivalTime ===
          flighItem.outboundFlights.flight.arrivalTime;

      const returnMatch =
        flighItemTarget.returnFlights?.flight && flighItem.returnFlights?.flight
          ? flighItemTarget.returnFlights.flight.departureDate ===
              flighItem.returnFlights.flight.departureDate &&
            flighItemTarget.returnFlights.flight.arrivalDate ===
              flighItem.returnFlights.flight.arrivalDate &&
            flighItemTarget.returnFlights.flight.departureTime ===
              flighItem.returnFlights.flight.departureTime &&
            flighItemTarget.returnFlights.flight.arrivalTime ===
              flighItem.returnFlights.flight.arrivalTime
          : true;

      if (outboundMatch && returnMatch) {
        if (
          parseInt(flighItemTarget.pricingOptions[0].price) <
          parseInt(flighItem.pricingOptions[0].price)
        ) {
          let newpricingOptions = [
            ...groupedFlights[indexTarget].pricingOptions,
            ...flighItemTarget.pricingOptions,
            ...flighItem.pricingOptions,
          ];

          newpricingOptions = Array.from(
            new Map(
              newpricingOptions.map((item) => [JSON.stringify(item), item])
            ).values()
          );

          groupedFlights[indexTarget].pricingOptions = newpricingOptions.sort(
            pricingOptionsSortByPrice
          );

          groupedFlights[index].deleted = true;
        }
      }
    }
  }

  return groupedFlights.filter((flight: any) => !flight.deleted);
}

interface FlightData {
  outboundFlights?: {
    flight: {
      originAirport: string;
      destinationAirport: string;
    };
  };
  returnFlights?: {
    flight: {
      originAirport: string;
      destinationAirport: string;
    };
  };
}

function extractIataCodes(sksFlights: FlightData[]): AirportsFilterProps[] {
  const airportCodesMap: Record<string, boolean> = {};
  const airportCodes: string[] = [];

  sksFlights.forEach((flight) => {
    if (flight.outboundFlights?.flight) {
      airportCodesMap[flight.outboundFlights.flight.originAirport] = true;
      airportCodesMap[flight.outboundFlights.flight.destinationAirport] = true;
    }

    if (flight.returnFlights?.flight) {
      airportCodesMap[flight.returnFlights.flight.originAirport] = true;
      airportCodesMap[flight.returnFlights.flight.destinationAirport] = true;
    }
  });

  for (const code in airportCodesMap) {
    if (airportCodesMap.hasOwnProperty(code)) {
      airportCodes.push(code);
    }
  }

  const airports: AirportsFilterProps[] = airportCodes.map((iata) => ({
    iata,
    name: airportsData.filter((item) => item.airport_code === iata)[0]
      ?.airport_name,
    isChecked: true,
  }));

  return airports;
}

function getMaxFlightDuration(flightData: any): number {
  let maxDuration = 0;

  flightData.map((item: any) => {
    if (item.outboundFlights.flight.durationFlight > maxDuration) {
      maxDuration = item.outboundFlights.flight.durationFlight;
    }

    if (item?.returnFlights) {
      if (item.returnFlights.flight.durationFlight > maxDuration) {
        maxDuration = item.returnFlights.flight.durationFlight;
      }
    }
  });

  return Math.round(maxDuration / 30) * 30;
}

function getMinFlightDuration(flightData: any): number {
  let minDuration = 100000;

  flightData.map((item: any) => {
    if (item.outboundFlights.flight.durationFlight < minDuration) {
      minDuration = item.outboundFlights.flight.durationFlight;
    }

    if (item?.returnFlights) {
      if (item.returnFlights.flight.durationFlight < minDuration) {
        minDuration = item.returnFlights.flight.durationFlight;
      }
    }
  });

  return Math.round(minDuration / 30) * 30;
}

export {
  sortByPrice,
  groupFlights,
  extractIataCodes,
  getMaxFlightDuration,
  getMinFlightDuration,
};
