/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./FlightCard.css";
import * as IconLibrary from "react-icons/fi";
import Api from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  OwnProps,
  flightDataProps,
  flightDetailsProps,
  flightProps,
} from "./types";
import { useRef } from "react";
import { myCustomLocale } from "../../DataEntry/CalendarPicker/const";
import {
  differenceDaysBetweenDates,
  differenceInMinutes,
  minuteToHour,
  parseAmout,
  parseDateString,
} from "../../../core/helpers/DateTime";
import { IoAirplane } from "react-icons/io5";
import {
  FiArrowRight,
  FiClock,
  FiChevronRight,
  FiChevronUp,
} from "react-icons/fi";
import Button from "../../Buttons/Button";
import { toUnicode } from "punycode";
import Icon from "../../Icon";
import { FiArrowUpRight, FiArrowDownLeft } from "react-icons/fi";
import airportsData from "../../Search/SearchFlights/InputSearch/airports.json";
import { airportPros } from "../../Search/SearchFlights/InputSearch/types";
import { FiX } from "react-icons/fi";
import { getAgentsFilter } from "../../../store/flights";
import ViewportChecker from "../../../core/helpers/ViewPort";
import airlines from "../../../core/constants/airlines/airlines.json";
function FlightCard({
  flight,
  currentPage,
  iconName,
  onClick,
  isShowOnModel,
  isFlightDetailsStartedOpen,
  onClose,
  onAnchorSellers,
  gtmFlightCardEvent,
}: OwnProps) {
  const [isShowFlightDetails, handlerFlightDetails] = useState<boolean>(false);
  const agentsFilterList = useSelector(getAgentsFilter);
  const isMobile = ViewportChecker();
  const divRef = useRef<HTMLDivElement>(null);

  const handleScrollToDiv = () => {
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        // window.scrollBy(0, 50);
      }
    }, 10);
  };

  useEffect(() => {
    handlerFlightDetails(false);
  }, [currentPage]);

  const isImageValid = (imageUrl: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  };

  const flightComponent = (
    flightItem: {
      flight: flightProps;
      flightsDetails: flightDetailsProps[];
    },
    isDeparture?: boolean
  ) => {
    return (
      <div className="flight-component">
        <div className="div-image-flight-component">
          <img
            className="image-flight"
            src={`https://voopter.com.br/voopter-static-assets/airline-logos/${flightItem.flight.airline}.png`}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              const nextNode = target.nextSibling;

              if (
                nextNode instanceof HTMLElement &&
                nextNode.classList.contains("text-airline-image-error")
              ) {
                return;
              }

              const errorSpan = document.createElement("span");
              errorSpan.textContent =
                airlines.filter(
                  (airlinesItem) =>
                    airlinesItem.id === flightItem.flight.airline
                )[0]?.name || flightItem.flight.airline;

              errorSpan.className = "text-airline-image-error";

              target.parentNode?.appendChild(errorSpan);

              target.style.display = "none";

              console.log("ERRO AO CARREGAR A IMAGEM");
            }}
          />
        </div>
        <div className="div-leg-component">
          <div className="div-leg-date">
            {/* <div className="departure-date-item-day-right"> */}

            {/* </div> */}

            <div className="div-leg-date-content">
              <div className="div-leg-date-content-left">
                {isDeparture ? (
                  <FiArrowUpRight className="icon-leg-date" />
                ) : (
                  <FiArrowDownLeft className="icon-leg-date" />
                )}
              </div>

              <div className="div-leg-date-content-right">
                <samp className="text-leg-day">
                  {`${parseDateString(flightItem.flight.departureDate).day}/${
                    parseDateString(flightItem.flight.departureDate).month
                  }`}
                </samp>

                <samp className="text-leg-day-week">
                  {
                    myCustomLocale.weekDays[
                      parseDateString(flightItem.flight.departureDate)?.weekDay
                    ]?.name
                  }
                  {/* {myCustomLocale.months[
                parseDateString(flightItem.flight.departureDate).month - 1
              ]?.substring(0, 3)} */}
                </samp>
              </div>
            </div>
          </div>
          <div className="div-leg-data">
            <div className="div-leg-data-up">
              <div className="div-leg-data-up-left">
                <samp className="text-time">
                  {flightItem.flight?.departureTime}
                </samp>
                <samp className="text-airport">
                  {flightItem.flight.originAirport}
                </samp>
              </div>
              <div className="div-leg-data-up-mid">
                <samp
                  className="text-direct-stop"
                  style={{
                    color:
                      flightItem.flight.stops === 0
                        ? "#219653"
                        : flightItem.flight.stops >= 3
                        ? "#EB5757"
                        : "#F2994A",
                  }}
                >
                  {flightItem.flight.stops === 0
                    ? "Direto"
                    : flightItem.flight.stops > 1
                    ? `${flightItem.flight.stops} paradas`
                    : `${flightItem.flight.stops} parada`}
                </samp>
                <div className="div-leg-stops">
                  <div
                    className="div-leg-stops-line"
                    style={{
                      backgroundColor:
                        flightItem.flight.stops === 0
                          ? "#219653"
                          : flightItem.flight.stops >= 3
                          ? "#EB5757"
                          : "#F2994A",
                    }}
                  >
                    <div
                      className="div-leg-stops-dot-div"
                      style={{
                        width: flightItem.flight.stops > 1 ? "60%" : "auto",
                      }}
                    >
                      {flightItem.flightsDetails.slice(1).map((item, index) => (
                        <div
                          key={`${index}-div-leg-stops-do`}
                          className="div-leg-stops-dot"
                          style={{
                            backgroundColor:
                              flightItem.flight.stops === 0
                                ? "#219653"
                                : flightItem.flight.stops >= 3
                                ? "#EB5757"
                                : "#F2994A",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <IoAirplane
                    className="icon-leg-stops-line"
                    style={{
                      color:
                        flightItem.flight.stops === 0
                          ? "#219653"
                          : flightItem.flight.stops >= 3
                          ? "#EB5757"
                          : "#F2994A",
                    }}
                  />
                </div>
                <div className="div-leg-duration">
                  <samp className="text-duration">
                    {minuteToHour(flightItem.flight.durationFlight)}
                  </samp>
                </div>
              </div>
              <div className="div-leg-data-up-right">
                <div className="div-leg-data-up-left">
                  <div className="div-text-time">
                    <samp className="text-time">
                      {flightItem.flight.arrivalTime}
                    </samp>
                  </div>

                  <samp className="text-airport">
                    {flightItem.flight.destinationAirport}
                  </samp>
                </div>

                {differenceDaysBetweenDates(
                  flightItem.flight.departureDate,
                  flightItem.flight.arrivalDate
                ) > 0 && (
                  <samp className="text-difference-between-dates">{`+${differenceDaysBetweenDates(
                    flightItem.flight.departureDate,
                    flightItem.flight.arrivalDate
                  )}`}</samp>
                )}
              </div>
            </div>

            {/* <span>
              {`${
                flightItem.flight.departureDate
              } | ${flightItem.flight.arrivalDate}`}
            </span> */}

            <div className="div-leg-data-down">
              {/* <div className="div-leg-data-down-left">
                <samp className="text-airport">
                  {flightItem.flight.originAirport}
                </samp>
              </div> */}

              {/* <div className="div-leg-data-down-mid">
                <samp className="text-direct-stop">
                  {flightItem.flight.stops === 0
                    ? 'Direto'
                    : flightItem.flight.stops > 1
                    ? `${flightItem.flight.stops} paradas`
                    : `${flightItem.flight.stops} parada`}
                </samp>
              </div> */}

              {/* <div className="div-leg-data-down-right">
                <samp className="text-airport">
                  {flightItem.flight.destinationAirport}
                </samp>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const converterData = (data: string) => {
    const partesData = data.split("/");
    const dia = partesData[0].padStart(2, "0");
    const mes = partesData[1].padStart(2, "0");
    const ano = partesData[2];
    return `${ano}${mes}${dia}`;
  };

  const getDeeplink = (
    flight: flightDataProps,
    item: {
      integrationName: string;
      price: string;
      agent: {
        name: string;
        logo: string;
      };
      redirectDetails: {
        webDeeplink: string;
        mobileDeeplink: string;
      };
    }
  ) => {
    const redirectBaseURL = "https://voopter.com.br/redirect";
    let outboundDepartureDate: string;
    let returnDepartureDate: string | null = null;
    let price = (parseFloat(item.price) / 100).toFixed(2);

    outboundDepartureDate = converterData(
      flight.outboundFlights.flight.departureDate
    );

    if (flight?.returnFlights) {
      returnDepartureDate = converterData(
        flight.returnFlights.flight.departureDate
      );
    }

    const deepLinkURL = `${redirectBaseURL}?url=${encodeURIComponent(
      item.redirectDetails.webDeeplink
    )}&domain=${item.agent.name}&sale[totalCost]=BRL${price}&sale[origin]=${
      flight.outboundFlights.flight.originAirport
    }&sale[destination]=${
      flight.outboundFlights.flight.destinationAirport
    }&sale[leaveDate]=${outboundDepartureDate}&sale[data2]=${getData2Param(
      item.integrationName
    )}&sale[data3]=${flight.outboundFlights.flight.airline}&sale[data4]=${
      item.agent.name
    }&sale[nAdults]=1&sale[nChildren]=0&sale[nBabies]=0${
      returnDepartureDate ? `&sale[returnDate]=${returnDepartureDate}` : ""
    }`;

    return deepLinkURL;
  };

  const getData2Param = (integrationName: string) => {
    switch (integrationName) {
      case "skyscanner":
        return "sk";
      case "kiwi":
        return "kiwi";
      case "amadeus-generic":
        return "amadeus";
      case "amadeus-latam":
        return "latam";
      case "chalinga":
        return "chalinga";
      case "zupper":
        return "zupper";
      case "latamz":
        return "latam";
      case "golz":
        return "gol";
      case "capo":
        return "capo";
      case "vaiDePromo":
        return "vaiDePromo";
    }
  };

  const getAirportData = (airportCode: string): airportPros => {
    const airpotData = airportsData.filter(
      (item) => item.airport_code === airportCode
    )[0];

    return airpotData;
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }
    return text;
  };

  const getFlightContextColor = (flightsDetailsLength: number): string => {
    const contextColor =
      flightsDetailsLength < 2
        ? "#219653"
        : flightsDetailsLength > 3
        ? "#EB5757"
        : "#F2994A";

    return contextColor;
  };

  return (
    <div
      className="div-flight-card"
      ref={divRef}
      style={{
        marginTop: isShowOnModel ? 0 : 20,
      }}
    >
      <div
        style={{
          border: isShowOnModel ? "0px solid #c8c8c8" : "1px solid #c8c8c8",
          borderLeft: isShowOnModel ? "0px solid #c8c8c8" : "1px solid #c8c8c8",
          borderRight: isShowOnModel
            ? "0px solid #c8c8c8"
            : "0px solid #c8c8c8",
          borderRadius: isShowOnModel ? 0 : 12,
        }}
        className={
          flight.returnFlights
            ? "div-item-flight-card"
            : "div-item-flight-card-oneway"
        }
      >
        <div
          className="div-flight-preview"
          style={{
            height: isShowOnModel ? "auto" : flight?.returnFlights ? 180 : 150,
          }}
          onClick={() => {
            if (!isMobile) {
              handlerFlightDetails(!isShowFlightDetails);
              handleScrollToDiv();
              gtmFlightCardEvent && gtmFlightCardEvent();
            } else {
              isMobile && onClick && onClick();
            }
          }}
        >
          <div className="div-left-flight-preview">
            {/* Uncommit this line for show uniqueID in flightCards */}
            {/* {flight.uniqueID} */}
            {flightComponent(flight.outboundFlights, true)}

            {flight?.returnFlights && <div className="dashed-line"></div>}

            {flight?.returnFlights && flightComponent(flight?.returnFlights)}
          </div>
          {!isShowOnModel && (
            <div className="div-right-flight-preview">
              <div className="div-text-price">
                <div className="div-text-price-content">
                  <span className="text-price-currency">R$</span>

                  <span className="text-price">
                    {
                      parseAmout(parseInt(flight.pricingOptions[0].price))
                        .beforeCents
                    }
                  </span>

                  <span className="text-price-cents">
                    {`,${
                      parseAmout(parseInt(flight.pricingOptions[0].price))
                        .cents ?? "00"
                    }`}
                  </span>
                </div>
              </div>

              <button className="button-flight-offer">
                <span className="button-text-offer">{"VEJA MAIS"}</span>

                <div className="div-right-arrow">
                  <Icon iconName="right-arrow" width={22} />
                </div>
              </button>
            </div>
          )}
        </div>

        {/* Card details */}

        {(isShowFlightDetails || isFlightDetailsStartedOpen) && (
          <>
            <div
              className="div-sellers-details"
              style={{ marginTop: isShowOnModel ? 0 : 20 }}
            >
              <div className="div-sellers-details-item ">
                {flight.pricingOptions.map((item, index) => (
                  <>
                    {item.price &&
                      agentsFilterList.filter(
                        (agentsFilterItem) =>
                          agentsFilterItem.agentName === item.agent.name
                      )[0].isChecked && (
                        <div key={`${index}-div-seller`} className="div-seller">
                          <div className="div-left-seller">
                            <div className="div-image-sellers">
                              <img
                                className="image-seller"
                                src={`${item.agent.logo}`}
                              />
                            </div>

                            {/* <div className="div-text-price"> */}
                            <div className="div-text-price-content-sellers">
                              <span
                                className="text-price-currency"
                                style={{ color: "#333333" }}
                              >
                                R$
                              </span>

                              <span
                                className="text-price"
                                style={{ color: "#333333" }}
                              >
                                {parseAmout(parseInt(item.price)).beforeCents}
                              </span>

                              <span
                                className="text-price-cents"
                                style={{ color: "#333333" }}
                              >
                                {`,${
                                  parseAmout(parseInt(item.price)).cents ?? "00"
                                }`}
                              </span>
                            </div>
                            {/* </div> */}
                          </div>
                          <div
                            className="div-right-seller"
                            onClick={() =>
                              window.open(getDeeplink(flight, item), "_blank")
                            }
                          >
                            <div className="div-right-seller-left-content">
                              <span
                                className={"text-right-top-seller-left-content"}
                              >
                                {"VER NO SITE"}
                              </span>

                              <span
                                className={
                                  "text-right-bottom-seller-left-content"
                                }
                              >
                                {item.agent.name}
                              </span>
                            </div>

                            <div className="div-right-seller-right-content">
                              <FiChevronRight className="icon-button-seller" />
                            </div>
                            {/* <div
                            className={'button-seller'}
                            onClick={() =>
                              window.open(getDeeplink(flight, item), '_blank')
                            }>
                            <div className="div-button-seller-text">
                              <span className={'text-title-button-seller'}>
                                {'COMPRAR NO SITE'}
                              </span>

                              <span className={'text-title-button-agent'}>
                                {item.agent.name}
                              </span>
                            </div>
                            <div className="div-button-seller-icon">
                              <FiChevronRight className="icon-button-seller" />
                            </div>
                          </div> */}
                          </div>
                        </div>
                      )}

                    {/* {index < flight.pricingOptions?.length - 1 && (
                      <div className="div-separator-seller" />
                    )} */}
                  </>
                ))}
              </div>
              <div className="div-message-before-flight-details">
                <span className="text-message-before-flight-details">
                  Confira os preços e detalhes da viagem antes de ir ao site
                  vendedor.
                </span>
              </div>
            </div>
            <div className="div-flight-details">
              <div className="div-flight-details-item">
                <div className="header-details-item">
                  <div className="div-title-header-details-item">
                    <span className="text-title-header-details-item">
                      DETALHES DA VIAGEM
                    </span>

                    <FiChevronUp className="icon-header-details-item" />
                  </div>

                  <div className="div-header-details-item">
                    <div className="div-header-details-item-left">
                      <Icon
                        iconName="calendar-up-right-arrow"
                        width={30}
                        iconColor="#333333"
                      />
                    </div>
                    <div className="div-header-details-item-mid">
                      <span className="text-top-header-details-item-mid-right">
                        {`Ida: ${flight.outboundFlights.flight.originAirport} - ${flight.outboundFlights.flight.destinationAirport}`}
                      </span>
                      <span className="text-bottom-header-details-item-mid-right">
                        {`${flight.outboundFlights.flight.departureDate}, ${
                          myCustomLocale.weekDays[
                            parseDateString(
                              flight.outboundFlights.flight.departureDate
                            )?.weekDay
                          ]?.name
                        }`}
                      </span>
                    </div>
                    <div className="div-header-details-item-right">
                      <span className="text-top-header-details-item-mid-right">
                        Duração total da ida:
                      </span>
                      <span className="text-bottom-header-details-item-mid-right">
                        {`${minuteToHour(
                          flight.outboundFlights.flight.durationFlight
                        )} (${
                          flight.outboundFlights.flightsDetails?.length > 1
                            ? `${flight.outboundFlights.flightsDetails?.length} voos`
                            : `${flight.outboundFlights.flightsDetails?.length} voo direto`
                        }) `}
                      </span>
                    </div>
                  </div>
                </div>

                {flight.outboundFlights.flightsDetails.map(
                  (flightDetailsItem, index) => (
                    <div
                      key={`${index}-div-body-details`}
                      className="div-body-details"
                    >
                      <div className="div-body-details-item">
                        <div className="div-details-item-up">
                          <div className="div-image-flight-number">
                            <img
                              className="image-airline-item-up"
                              src={`https://voopter.com.br/voopter-static-assets/airline-logos/${flightDetailsItem?.airline}.png`}
                            />

                            <span className="text-flight-number-item-left">
                              Voo nº
                            </span>

                            <span className="text-flight-number-item-right">
                              {flightDetailsItem?.flightNumber}
                            </span>
                          </div>
                        </div>
                        <div className="div-details-item-down">
                          <div
                            className="div-details-item-down-contetn"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: "auto",
                            }}
                          >
                            <div className="div-line-conection">
                              <div
                                className="dot-conection"
                                style={{
                                  backgroundColor: getFlightContextColor(
                                    flight.outboundFlights.flightsDetails
                                      ?.length
                                  ),
                                }}
                              />
                              <div
                                className="line-conection"
                                style={{
                                  backgroundColor: getFlightContextColor(
                                    flight.outboundFlights.flightsDetails
                                      ?.length
                                  ),
                                }}
                              />
                              <div
                                className="dot-conection"
                                style={{
                                  backgroundColor: getFlightContextColor(
                                    flight.outboundFlights.flightsDetails
                                      ?.length
                                  ),
                                }}
                              />
                            </div>
                            <div className="div-conections">
                              <div className="div-conections-top-content">
                                <samp
                                  className="text-time-conections"
                                  style={{
                                    whiteSpace: "nowrap",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {`${flightDetailsItem?.departureTime} ${flightDetailsItem?.originAirport} ·\u00A0`}
                                </samp>

                                {/* <samp className="text-place-conections">
                                  {truncateText(
                                    getAirportData(
                                      flightDetailsItem?.originAirport,
                                    )?.airport_name,
                                    20,
                                  )}
                                </samp> */}

                                <samp className="text-place-conections">
                                  {`${
                                    getAirportData(
                                      flightDetailsItem?.originAirport
                                    )?.airport_name
                                  }, ${
                                    getAirportData(
                                      flightDetailsItem?.originAirport
                                    )?.city_name
                                  }, ${
                                    getAirportData(
                                      flightDetailsItem?.originAirport
                                    )?.country_name
                                  }`}
                                </samp>
                              </div>

                              <samp className="text-duration-connection">
                                {`Duração do voo: ${minuteToHour(
                                  flightDetailsItem?.durationFlight
                                )}`}
                              </samp>

                              <div className="div-conections-top-content">
                                <samp
                                  className="text-time-conections"
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {`${flightDetailsItem?.arrivalTime} ${flightDetailsItem?.destinationAirport} ·\u00A0`}
                                </samp>

                                {/* <samp className="text-place-conections">
                                  {truncateText(
                                    getAirportData(
                                      flightDetailsItem?.destinationAirport,
                                    )?.airport_name,
                                    20,
                                  )}
                                </samp> */}

                                <samp className="text-place-conections">
                                  {`${
                                    getAirportData(
                                      flightDetailsItem?.destinationAirport
                                    )?.airport_name
                                  }, ${
                                    getAirportData(
                                      flightDetailsItem?.destinationAirport
                                    )?.city_name
                                  }, ${
                                    getAirportData(
                                      flightDetailsItem?.destinationAirport
                                    )?.country_name
                                  }`}
                                </samp>
                              </div>

                              {/* <samp className="text-duration-connection">
                              {minuteToHour(flightDetailsItem?.durationFlight)}
                            </samp>
                            <samp className="text-time-conections">
                              {flightDetailsItem?.arrivalTime}
                            </samp> */}
                            </div>

                            {/* <div className="div-arrow-conections">
                            <FiArrowRight className="icon-arrow-conections" />
                            <FiArrowRight className="icon-arrow-conections" />
                          </div> */}

                            {/* <div className="div-airports-names-stops">
                            <samp className="text-airport-name">
                              {`${flightDetailsItem?.originAirportName} - ${flightDetailsItem?.originAirport}`}
                            </samp>
                            <samp className="text-airport-name">
                              {`${flightDetailsItem?.destinationAirportName} - ${flightDetailsItem?.destinationAirport}`}
                            </samp>
                          </div> */}
                          </div>
                        </div>
                      </div>

                      {index + 1 <
                        flight.outboundFlights.flightsDetails?.length &&
                        flightDetailsItem.waitingTimeBetweenFlights && (
                          <div className="div-stop-division">
                            <samp className="text-time-stopped">
                              {/* <FiClock className="icon-clock-time-stopped" /> */}
                              <div className="dot-clock-time-stopped" />

                              {`Tempo entre voos: ${minuteToHour(
                                flightDetailsItem.waitingTimeBetweenFlights
                              )}`}
                              {/* {`Tempo entre voos: ${differenceInMinutes(
                              flightDetailsItem?.arrivalTime,
                              flight.outboundFlights.flightsDetails[index + 1]
                                ?.departureTime,
                            )}`} */}
                            </samp>
                          </div>
                        )}
                    </div>
                  )
                )}

                {flight?.returnFlights?.flight && (
                  <>
                    <div
                      className="div-header-details-item"
                      style={{ marginTop: 20 }}
                    >
                      <div className="div-header-details-item-left">
                        <Icon
                          iconName="calendar-down-left-arrow"
                          width={30}
                          iconColor="#333333"
                        />
                      </div>
                      <div className="div-header-details-item-mid">
                        <span className="text-top-header-details-item-mid-right">
                          {`Volta: ${flight.returnFlights.flight.originAirport} - ${flight.outboundFlights.flight.originAirport}`}
                        </span>
                        <span className="text-bottom-header-details-item-mid-right">
                          {`${flight.returnFlights.flight.departureDate}, ${
                            myCustomLocale.weekDays[
                              parseDateString(
                                flight.returnFlights.flight.departureDate
                              )?.weekDay
                            ]?.name
                          }`}
                        </span>
                      </div>
                      <div className="div-header-details-item-right">
                        <span className="text-top-header-details-item-mid-right">
                          Duração total da volta:
                        </span>
                        <span className="text-bottom-header-details-item-mid-right">
                          {`${minuteToHour(
                            flight.returnFlights.flight.durationFlight
                          )} (${
                            flight.returnFlights.flightsDetails?.length > 1
                              ? `${flight.returnFlights.flightsDetails?.length} voos`
                              : `${flight.returnFlights.flightsDetails?.length} voo direto`
                          }) `}
                        </span>
                      </div>
                    </div>
                    {flight.returnFlights.flightsDetails.map(
                      (flightDetailsItem, index) => (
                        <div
                          key={`${index}-div-body-details`}
                          className="div-body-details"
                        >
                          <div className="div-body-details-item">
                            <div className="div-details-item-up">
                              <div className="div-image-flight-number">
                                <img
                                  className="image-airline-item-up"
                                  src={`https://voopter.com.br/voopter-static-assets/airline-logos/${flightDetailsItem?.airline}.png`}
                                />

                                <span className="text-flight-number-item-left">
                                  Voo nº
                                </span>

                                <span className="text-flight-number-item-right">
                                  {flightDetailsItem?.flightNumber}
                                </span>
                              </div>
                            </div>
                            <div className="div-details-item-down">
                              <div
                                className="div-details-item-down-contetn"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  height: "auto",
                                }}
                              >
                                <div className="div-line-conection">
                                  <div
                                    className="dot-conection"
                                    style={{
                                      backgroundColor: getFlightContextColor(
                                        flight.returnFlights.flightsDetails
                                          ?.length
                                      ),
                                    }}
                                  />
                                  <div
                                    className="line-conection"
                                    style={{
                                      backgroundColor: getFlightContextColor(
                                        flight.returnFlights.flightsDetails
                                          ?.length
                                      ),
                                    }}
                                  />
                                  <div
                                    className="dot-conection"
                                    style={{
                                      backgroundColor: getFlightContextColor(
                                        flight.returnFlights.flightsDetails
                                          ?.length
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="div-conections">
                                  <div className="div-conections-top-content">
                                    <samp
                                      className="text-time-conections"
                                      style={{
                                        whiteSpace: "nowrap",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {`${flightDetailsItem?.departureTime} ${flightDetailsItem?.originAirport} ·\u00A0`}
                                    </samp>

                                    {/* 
                                    <samp className="text-place-conections">
                                      {truncateText(
                                        getAirportData(
                                          flightDetailsItem?.originAirport,
                                        )?.airport_name,
                                        20,
                                      )}
                                    </samp> */}

                                    <samp className="text-place-conections">
                                      {`${
                                        getAirportData(
                                          flightDetailsItem?.originAirport
                                        )?.airport_name
                                      }, ${
                                        getAirportData(
                                          flightDetailsItem?.originAirport
                                        )?.city_name
                                      }, ${
                                        getAirportData(
                                          flightDetailsItem?.originAirport
                                        )?.country_name
                                      }`}
                                    </samp>
                                  </div>

                                  <samp className="text-duration-connection">
                                    {`Duração do voo: ${minuteToHour(
                                      flightDetailsItem?.durationFlight
                                    )}`}
                                  </samp>

                                  <div className="div-conections-top-content">
                                    <samp
                                      className="text-time-conections"
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {`${flightDetailsItem?.arrivalTime} ${flightDetailsItem?.destinationAirport} ·\u00A0`}
                                    </samp>

                                    {/* <samp className="text-place-conections">
                                      {truncateText(
                                        getAirportData(
                                          flightDetailsItem?.destinationAirport,
                                        )?.airport_name,
                                        20,
                                      )}
                                    </samp> */}

                                    <samp className="text-place-conections">
                                      {`${
                                        getAirportData(
                                          flightDetailsItem?.destinationAirport
                                        )?.airport_name
                                      }, ${
                                        getAirportData(
                                          flightDetailsItem?.destinationAirport
                                        )?.city_name
                                      }, ${
                                        getAirportData(
                                          flightDetailsItem?.destinationAirport
                                        )?.country_name
                                      }`}
                                    </samp>
                                  </div>

                                  {/* <samp className="text-duration-connection">
                              {minuteToHour(flightDetailsItem?.durationFlight)}
                            </samp>
                            <samp className="text-time-conections">
                              {flightDetailsItem?.arrivalTime}
                            </samp> */}
                                </div>

                                {/* <div className="div-arrow-conections">
                            <FiArrowRight className="icon-arrow-conections" />
                            <FiArrowRight className="icon-arrow-conections" />
                          </div> */}

                                {/* <div className="div-airports-names-stops">
                            <samp className="text-airport-name">
                              {`${flightDetailsItem?.originAirportName} - ${flightDetailsItem?.originAirport}`}
                            </samp>
                            <samp className="text-airport-name">
                              {`${flightDetailsItem?.destinationAirportName} - ${flightDetailsItem?.destinationAirport}`}
                            </samp>
                          </div> */}
                              </div>
                            </div>
                          </div>

                          {index + 1 <
                            flight.returnFlights.flightsDetails?.length &&
                            flightDetailsItem.waitingTimeBetweenFlights && (
                              <div className="div-stop-division">
                                <samp className="text-time-stopped">
                                  {/* <FiClock className="icon-clock-time-stopped" /> */}
                                  <div className="dot-clock-time-stopped" />

                                  {`Tempo entre voos: ${minuteToHour(
                                    flightDetailsItem.waitingTimeBetweenFlights
                                  )}`}

                                  {/* {`Tempo entre voos: ${differenceInMinutes(
                                  flightDetailsItem?.arrivalTime,
                                  flight.returnFlights.flightsDetails[index + 1]
                                    ?.departureTime,
                                )}`} */}
                                </samp>
                              </div>
                            )}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>

              {/* End card details */}
              <div className="div-flight-details-footer">
                <div
                  className="div-flight-details-footer-left"
                  onClick={() => {
                    if (isMobile) {
                      onClose && onClose();
                    } else {
                      handlerFlightDetails(false);
                      // handleScrollToDiv();
                    }
                  }}
                >
                  <div className={"div-icon-flight-details-footer-left"}>
                    <span className="text-flight-details-footer-left">
                      FECHAR
                    </span>
                    <FiX
                      className="icon-flight-details-footer-left"
                      // onClick={() => onClose()}
                    />
                  </div>
                </div>

                <div
                  className="div-flight-details-footer-right"
                  onClick={() => {
                    if (isMobile) {
                      onAnchorSellers && onAnchorSellers();
                    } else {
                      handleScrollToDiv();
                    }
                  }}
                >
                  <span className="text-flight-details-footer-right">
                    ONDE COMPRAR
                  </span>

                  <Icon iconName="up-arrow" width={14} iconColor="#000000" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

FlightCard.defaultProps = {
  iconName: null,
  isFlightDetailsStartedOpen: false,
  isShowOnModel: false,
};

export default FlightCard;
